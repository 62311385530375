<template>
  <div class="container prow-40">
    <h1 class="title pt-20 pb-10">授权协议</h1>
    <p class="txt">
      使用“积分签到”应用功能，所有关注商城公众号的会员（包括分销商）可进行每日签到获得积分，累计获得的积分可换取积分商城的商品或购买商品时进行抵扣金额，商城也可设置连续签到领取积分，会员或分销商连续签到获得积分需要自己点击领取，商城还可以设置补签等功能设置。
    </p>
  </div>
</template>

<script>
export default {
  name: "LicenseAgreement",
};
</script>

<style lang="scss" scoped>
.title,
.txt {
  font-size: 0.28rem;
  color: #0a1738;
  line-height: 0.4rem;
  letter-spacing: 0.02rem;
}
</style>
